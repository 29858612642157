html {
  line-height: 1;
  background-color: #fff;
  font-size: 16px;
  min-width: 300px;
  text-rendering: optimizeLegibility;
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch !important;
}

* {
  &,
  &::before,
  &::after {
    box-sizing: inherit;
  }
}

html,
body,
p,
ol,
ul,
li,
dl,
dt,
dd,
blockquote,
figure,
fieldset,
legend,
textarea,
pre,
iframe,
hr,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

body {
  color: #4a4a4a;
  font-size: 1em;
  line-height: 1.5;
  -webkit-overflow-scrolling: touch !important;
  overflow-x: hidden !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}

ul {
  list-style: none;
}

button,
input,
select,
textarea {
  margin: 0;
  transition: width 0.2s linear;
}

img,
video {
  height: auto;
  max-width: 100%;
}

iframe {
  border: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;

  &:not([align]) {
    text-align: inherit;
  }
}

article,
aside,
figure,
footer,
header,
hgroup,
section {
  display: block;
}

body,
input,
optgroup,
select,
textarea {
}

code,
pre {
  -moz-osx-font-smoothing: auto;
  font-family: monospace;
}

hr {
  background-color: #333;
  border: none;
  display: block;
  height: 2px;
  margin: 1.5rem 0;
}

img {
  height: auto;
  max-width: 100%;
  object-fit: cover;
}

input[type="checkbox"],
input[type="radio"] {
  vertical-align: baseline;
}

small {
  font-size: 0.875em;
}

span {
  font-style: inherit;
  font-weight: inherit;
}

strong {
  color: #333;
  font-weight: bold;
}

fieldset {
  border: none;
}

table {
  td,
  th {
    vertical-align: top;
    text-align: inherit;
  }

  th {
    color: #333;
  }
}

.react-calendar {
  width: 350px;
  max-width: 100%;
  background: #fff;
  border: 1px solid #a0a096;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}

.react-calendar--doubleView {
  width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar :after,
.react-calendar :before {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: 0;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  height: 44px !important;
  margin-bottom: 1em;
}

.react-calendar__navigation button {
  min-width: 44px;
  background: 0 0;
}

.react-calendar__navigation button:enabled:focus,
.react-calendar__navigation button:enabled:hover {
  background-color: #e6e6e6;
}

.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.75em;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}

.react-calendar__month-view__weekNumbers {
  font-weight: 700;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}

.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}

.react-calendar__century-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__year-view .react-calendar__tile {
  padding: 2em 0.5em;
}

.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  padding: 0.75em 0.5em;
  background: 0 0;
}

.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}

.react-calendar__tile:enabled:focus,
.react-calendar__tile:enabled:hover {
  background-color: #e6e6e6;
}

.react-calendar__tile--now {
  background: #ffff76;
}

.react-calendar__tile--now:enabled:focus,
.react-calendar__tile--now:enabled:hover {
  background: #ffffa9;
}

.react-calendar__tile--hasActive {
  background: #76baff;
}

.react-calendar__tile--hasActive:enabled:focus,
.react-calendar__tile--hasActive:enabled:hover {
  background: #a9d4ff;
}

.react-calendar__tile--active {
  background: #006edc;
  color: #fff;
}

.react-calendar__tile--active:enabled:focus,
.react-calendar__tile--active:enabled:hover {
  background: #1087ff;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

.react-calendar {
  width: min-content;
  min-width: 100%;
  margin-top: 26px;
  background: #ffffff;
  border-radius: 2px;

  border: 1px solid #e5e5e5;

  .react-calendar__navigation {
    height: auto;
    margin-bottom: 0;

    .react-calendar__navigation__label {
      padding: 5px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #e5e5e5;

      &__divider {
        display: none;
      }
    }

    button[disabled] {
      background-color: #fff;
      color: #000;
    }
  }

  .react-calendar__navigation__label__labelText {
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
  }

  .react-calendar__navigation__arrow {
    font-size: 30px;
    padding-bottom: 8px;
    border: 1px solid #e5e5e5;
  }

  .react-calendar__tile {
    line-height: 1.7;
    font-size: 14px;
    font-weight: 400;
    padding: 0.5em;

    &:disabled {
      background-color: #fff;
      color: #828282;
    }
  }

  .react-calendar__month-view__days__day {
    background-color: #dbfddd;
    border: 1px solid #fff;

    &--weekend {
      color: inherit;
    }

    @media (max-width: 350px) {
      font-size: 12px;
    }

    &.yellow {
      background: #fffaca;
    }
  }

  abbr[title] {
    text-decoration: unset !important;
  }

  .react-calendar__tile--active {
    background: #ff385c;
    color: #fff;

    &:enabled:focus {
      background: #ff385c;
    }

    &:enabled:hover {
      background: #ff385c;
      opacity: 0.9;
    }

    &.yellow {
      background: #ff385c;
    }
  }
}

.TooltipContent {
  border-radius: 4px;
  padding: 10px 15px;
  font-size: 15px;
  line-height: 1;
  color: var(--violet-11);
  background-color: white;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  user-select: none;
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
}
.TooltipContent[data-state="delayed-open"][data-side="top"] {
  animation-name: slideDownAndFade;
}
.TooltipContent[data-state="delayed-open"][data-side="right"] {
  animation-name: slideLeftAndFade;
}
.TooltipContent[data-state="delayed-open"][data-side="bottom"] {
  animation-name: slideUpAndFade;
}
.TooltipContent[data-state="delayed-open"][data-side="left"] {
  animation-name: slideRightAndFade;
}

.TooltipArrow {
  fill: white;
}

.IconButton {
  font-family: inherit;
  border-radius: 100%;
  height: 35px;
  width: 35px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--violet-11);
  background-color: white;
  box-shadow: 0 2px 10px black;
}
.IconButton:hover {
  background-color: purple;
}
.IconButton:focus {
  box-shadow: 0 0 0 2px black;
}

@keyframes slideUpAndFade {
  from {
    opacity: 0;
    transform: translateY(2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideRightAndFade {
  from {
    opacity: 0;
    transform: translateX(-2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideDownAndFade {
  from {
    opacity: 0;
    transform: translateY(-2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideLeftAndFade {
  from {
    opacity: 0;
    transform: translateX(2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
